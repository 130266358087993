import React, { useState,useRef } from "react";
import Footer from "../../_components/Footer/Footer";
import Header from "../../_components/Header/Header";
import styles from "./styles.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import emailjs from '@emailjs/browser';
const Contact = () => {
  const currentUrl = window.location.href;
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState(0);
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [contacted, setContacted] = useState(false);
  const form = useRef();
  const onClick = async (e) => {

    e.preventDefault();

    emailjs.sendForm('service_pxu4t6t', 'template_b79tfue', form.current, '7cLiZ9AvzoUFiJMR8')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div
      className={`${styles["contact"]}  width-100 height-100 font-family-inter`}
    >
      <Helmet>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Header />
      <ToastContainer />
      <div
        className={`d-flex ${isMobile && `flex-column`} ${
          styles["contact-us"]
        }`}
      >
        <div
          className={`${styles["left-contact-us"]} ${
            isMobile && `width-100 margin-top40`
          } d-flex flex-column justify-content-center`}
        >
          <div className="d-flex ">
            <p className={`${isMobile ? `fs-16` : "fs-20"}`}>
              Call or WhatsApp :-{" "}
              <a className="fw-700" href="tel:+917828961990">
                +91 78289&nbsp;61990
              </a>
            </p>
            &nbsp;
          </div>
          <div className="d-flex margin-top20">
            <p className={`${isMobile ? `fs-16` : "fs-20"}`}>
              E-Mail :-{" "}
              <a className="fw-700" href="mailto:contact@famexmarketing.com">
              contact@famexmarketing.com
              </a>
            </p>
            &nbsp;
          </div>
          <div className="d-flex margin-top20">
            <p className={`${isMobile ? `fs-16` : "fs-20"}`}>
              To join our team :-{" "}
              <a className="fw-700" href="mailto:contact@famexmarketing.com">
                Send your resume at contact@famexmarketing.com
              </a>
            </p>
          </div>
        </div>
        <div
          className={`${styles["right-contact-us"]} ${isMobile && `width-100`}`}
        >
          <div className={`${isMobile && `width-100`}`}>
            <form ref={form} onSubmit={onClick}>
            <p className="fs-20 fw-700 margin-top40">Get In Touch</p>
            <label htmlFor="first">
              First Name<span className="red">*</span>
            </label>
            <input
              type="text"
              required={true}
              onChange={(e) => setFirst(e.target.value)}
              id="first"
              className="first"
              name="first"
            />
            <label htmlFor="last">
              Last Name<span className="red">*</span>
            </label>
            <input
              type="text"
              required={true}
              onChange={(e) => setLast(e.target.value)}
              id="last"
              className="last"
              name="last"
            />
            <label htmlFor="email">
              E-mail<span className="red">*</span>
            </label>
            <input
              type="text"
              required={true}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              className="email"
              name="email"
            />
            <label htmlFor="number">Phone Number</label>
            <input
              type="number"
              id="number"
              onChange={(e) => setNumber(e.target.value)}
              className="number"
              name="number"
            />
            <label htmlFor="company">
              Company Name<span className="red">*</span>
            </label>
            <input
              type="text"
              required={true}
              onChange={(e) => setCompany(e.target.value)}
              id="company"
              className="company"
              name="company"
            />
            <label htmlFor="message">
              Message<span className="red">*</span>
            </label>
            <textarea
              name="message"
              required={true}
              onChange={(e) => setMessage(e.target.value)}
              id="message"
              className={`${styles["message"]}`}
              cols="30"
              rows="10"
            ></textarea>
            <button
            type="submit"
              onClick={() => {
                onClick();
              }}
            >
              Submit
            </button>
            </form>
          </div>
        </div>
      </div>
      {/* <h1 className="fw-500 font-family-poppins pt-4 pb-4">Our Reach- <span className="colorPrimary fw-700">350+ Cities</span></h1>
        <div className={`${styles['contact-us']} d-flex`}>
            <div className="d-flex justify-content-end"><ImageWithIcon image={aboutUsHomeImg} whiteBorder={true} icon={step1Icon}/></div>
            <div className={`d-flex flex-column justify-content-center ${styles['contact-detail']}`}>
                <div className="d-flex align-item-center"><a href={`tel:+91 ${contents.mobile_number}`}><img src={micIcon} alt="" /></a><p className="d-flex align-items-center"><a href={`tel:+91 ${contents.mobile_number}`}>+91 {contents.mobile_number}</a></p></div>
                <div className="d-flex align-item-center"><a href = {`mailto: ${contents.email}`}><img src={planeIcon} alt="" /></a><p className="d-flex align-items-center"><a href = {`mailto: ${contents.email}`}>{contents.email}</a></p></div>
                <div className="d-flex align-item-center"><a><img src={timeIcon} alt="" /></a><p className="d-flex align-items-center">{contents.work_time}</p></div>
            </div>
        </div> */}
      <Footer />
    </div>
  );
};
export default Contact;
