import React from "react";
import Footer from "../../_components/Footer/Footer";
import Header from "../../_components/Header/Header";
import styles from "./styles.module.css";
import { aboutHeroImg } from "../../_constants/images_assets";
import ServicesCard from "../../_components/ServicesPage/ServicesCard/ServicesCard";
import ServicesFaq from "../../_components/ServicesPage/ServicesFaq/ServicesFaq";
import Industries from "../../_components/ServicesPage/Industries/Industries";
import { Helmet } from "react-helmet";
const Services = () => {
  const currentUrl = window.location.href;
  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div
      className={`${styles["services"]}  width-100 height-100 font-family-inter`}
    >
      {/* <Helmet>
        <link rel="canonical" href={currentUrl} />
      </Helmet> */}
      <Header />
      <h1
        className={`${
          isMobile ? `fs-28 margin-top40` : "fs-75"
        } text-align-center width-100`}
      >
        A wide range of <span className="colorPrimary fw-700">services</span>
        <br /> you can think of
      </h1>
      <ServicesCard />
      {/* <ServicesFaq/> */}
      {/* <Industries/> */}
      <Footer />
    </div>
  );
};
export default Services;
