
import React from "react";
import styles from "./styles.module.css"
import { rightArrowWhite,aboutUsHomeImg,aboutUsImageIcon, visionImg } from "../../../_constants/images_assets";
import ImageWithIcon from "../../ImageWithIcon/ImageWithIcon";
import { contents } from "../../../_constants/constant";

const OurVision=({head1,head2,para,buttonTxt,darkBack,para2,link="/about"})=>{
    const isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;
    return (
        <div className={`d-flex ${isMobile && `flex-column`} justify-content-center align-items-center ${!isMobile && `padding-left90`} ${styles['about-us']} ${darkBack?styles['darkBack']:""}`}>
            {!isMobile?<div><ImageWithIcon image={visionImg} icon={aboutUsImageIcon}/></div>
            :
            <div className="width-100">
                <img src={aboutUsHomeImg} alt="" width={"100%"}/>    
            </div>}
            <div className={`${isMobile && `width-100 d-flex flex-column align-items-center margin-bottom40`}`}>
                <h2 className="fs-40 font-family-poppins">Our Vision</h2>
                <p className="fs-18 font-family-inter">Fame X is a forward-thinking marketing agency with a vision to revolutionize the industry. We believe in combining creativity with cutting-edge technological tactics to deliver innovative and effective marketing solutions for our clients. We are here to empower businesses with the tools and strategies needed to reach their target audience and achieve their goals. Our aim is to make our client shine in a competitive market by delivering tailored and data-backed solutions through our customer-focused approach and a skilled team of professionals. Our primary objective is to become the preferred agency for companies seeking to enhance their brand and maintain a competitive edge in a constantly changing digital environment.</p>
                {/* <p className="fs-18 font-family-inter">{contents.about_us_services_para2}</p> */}
                {/* <button className="backColorPrimary colorWhite fs-18"  onClick={()=>{window.location.pathname=link}}>{buttonTxt}<img src={rightArrowWhite} alt="" /></button> */}
            </div>
        </div>
    )
}
export default OurVision