
import React from "react";
import styles from "./styles.module.css"

const ImageWithIcon=({image,icon,whiteBorder,leftImg,className})=>{
    return (
        <div className={`${styles['image-with-icon']} ${className}`}>
            <img src={image} alt=""/>
            <div className={`backColorPrimary d-flex justify-content-center align-items-center ${whiteBorder?styles['white_border']:""} ${leftImg?styles['left_icon']:""}`}>
                {!icon ?"": <img src={icon} alt=""  />} 
            </div>
        </div>
    )
}
export default ImageWithIcon