import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  rightArrowBlack,
  rightArrowWhite,
} from "../../../_constants/images_assets";
import { contents, home_page_title } from "../../../_constants/constant";

const HomeHero = (props) => {
  const [text, setText] = useState("");
  const [currentStringIndex, setCurrentStringIndex] = useState(0);

  const stringsToType = contents.home_page_typing;
  useEffect(() => {
    const currentString = stringsToType[currentStringIndex];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setText(currentString.slice(0, currentIndex + 1));
      currentIndex++;

      if (currentIndex === currentString.length) {
        clearInterval(intervalId);

        // Move to the next string or start over if at the end
        if (currentStringIndex === stringsToType.length - 1) {
          setCurrentStringIndex(0);
        } else {
          setCurrentStringIndex(currentStringIndex + 1);
        }
        setTimeout(() => {
          // setText('');
        }, 2000);
      }
    }, 220); // adjust the typing speed as desired

    return () => clearInterval(intervalId);
  }, [currentStringIndex, stringsToType]);
  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${styles["home-hero"]} colorWhite`}
    >
      <h2 className="fs-28 font-family-poppins fw-600">
        {/* {contents.home_page_title} */}
        <br />
        {isMobile && <br />}
        {/* <span className="fw-700">Branding</span> */}
        <span class={`fw-700 ${isMobile ? `fs-28` : "fs-60"}`}>{text}</span>
        {/* {contents?.home_page_typing.map((item,index)=>{
                return <span class={`${styles[`text_${index}`]} fw-700`}>{item}</span>
            })} */}
        {/* <span class={`${styles['text_2']} fw-700`}>{contents.home_page_typing2}</span> */}
        {/* <span class={`${styles['text_3']} fw-700`}>Digital Services</span> */}
      </h2>
      <p
        className={`fs-18 font-family-inter fw-700 ${
          isMobile && `width-100 margin-top40`
        }`}
      >
        {contents.home_page_quote}
      </p>
      <button
        className="fs-18"
        onClick={() => {
          window.location.pathname = "/contact";
        }}
      >
        LET’S CHECK HOW IT WORKS <img src={rightArrowWhite} alt="" />
      </button>
    </div>
  );
};
export default HomeHero;
