import React from "react";
import Footer from "../../_components/Footer/Footer";
import Header from "../../_components/Header/Header";
import AboutUs from "../../_components/HomePage/AboutUs/AboutUs";
import HomeHero from "../../_components/HomePage/HomeHero/HomeHero";
import Industries from "../../_components/HomePage/Industries/Industries";
import Services from "../../_components/HomePage/Services/Services";
import Steps from "../../_components/HomePage/Steps/Steps";
import styles from "./styles.module.css";
import {
  aboutHeroImg,
  AboutUsImage,
  facebookIcon,
  instagramIcon,
  linkedinIcon,
} from "../../_constants/images_assets";
import AboutHero from "../../_components/AboutPage/AboutHero/AboutHero";
import Quote from "../../_components/AboutPage/Quote/Quote";
import { contents } from "../../_constants/constant";
import { Helmet } from "react-helmet";

const About = () => {
  const currentUrl = window.location.href;
  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div
      className={`${styles["about"]} width-100 height-100 font-family-inter`}
    >
      <Helmet>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Header />
      <div
        className={`d-flex ${styles["about-us"]} ${isMobile && `flex-column`}`}
      >
        <div
          className={`${styles["left-about-us"]} ${
            isMobile && `width-100 height-200p`
          }`}
        >
          {/* <img src={AboutUsImage} alt="" /> */}
        </div>
        <div
          className={`${styles["right-about-us"]} padding-left10p ${
            isMobile && `width-100 ${styles["padding-left-right"]}`
          }`}
        >
          <p
            className={`fs-60 margin-top40 ${isMobile && `text-align-center`}`}
          >
            Hello
          </p>
          <p className="margin-top40">
          Fame X is a distinguished marketing and communication firm specializing in the development of innovative strategies tailored to address unique challenges and drive impactful solutions.
          </p>
          <button className={`margin-top20 `}>
            Does Your Brand Need Supreme Branding? Or Marketing?
          </button>
          <p className="margin-top20">Our relentless pursuit is to craft impeccable marketing strategies that propel our clients to the forefront of their industry, captivating a larger audience. With a team of seasoned experts, we are committed to delivering exceptional and bespoke content that drives outstanding outcomes for our valued clients.</p>
          <p className="fs-28 fw-700 margin-top40">
          Why are we unique?
          </p>
          <p className="margin-top20">
          We strive assiduously to provide our clients with useful help. As we offer helpful support in a range of trading industries, we concentrate on innovation-driven creative marketing. We are constantly looking for methods to support our client's success, whether by creating a fresh social media strategy or coming up with a creative approach to reaching out to potential customers.
          </p>
          <p className="fs-28 fw-700 margin-top40">
          PERKS OF PARTNERING WITH FAME X
          </p>
          <p className="fw-700 margin-top20">
          Ready for the Fame X advantage? Why miss out on the fun and professional benefits we offer?
          </p>
          <p className="margin-top20">
          We are innate with the core values of creativity, innovation, and results and have extensive knowledge in optimizing content and promoting it effectively to reach a wider audience. The drive of passion of assisting our clients, and achieving high-quality, customized content that will not only help to reach the target goals but become a family with our clients.
          </p>
          <div
            className={`margin-top40 d-flex ${
              isMobile && `justify-content-center`
            }`}
          >
            <a href={contents.links.facebook}>
              <img
                src={facebookIcon}
                width="30px"
                height={"25px"}
                className={`margin-right10`}
                alt=""
              />
            </a>
            <a href={contents.links.linkedin}>
              <img
                src={linkedinIcon}
                width="30px"
                height={"30px"}
                className={`margin-right10`}
                alt=""
              />
            </a>
            <a href={contents.links.instagram}>
              <img src={instagramIcon} width="28px" height={"28px"} alt="" />
            </a>
          </div>
        </div>
      </div>
      {/* <AboutHero/>
        <img src={aboutHeroImg} alt="" />
        <Quote/>
        <AboutUs link={"/services"} darkBack={true} buttonTxt={"Services"}/> */}
      {/* <Industries/> */}
      <Footer />
    </div>
  );
};
export default About;
