import React from "react";
import styles from "./styles.module.css"
import { heroImg,homeServiceIcon1,rightArrowWhite, serviceIcon2, serviceIcon4, serviceIcon5, serviceIcon6, serviceIcon7, serviceIcon8, serviceIcon9 } from "../../../_constants/images_assets";
import Card from "./Card";
import { contents } from "../../../_constants/constant";

const services=contents.services
const ServicesCard =()=>{
    const isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;
    return (<div className={`d-flex ${isMobile && `flex-column ${styles['padding-right5p']}`} justify-content-around ${styles['services-card']}`}>
        {services && services?.map(service=>{
            return <Card service={service}/>
        })}
    </div>)
}
export default ServicesCard