import React from "react";
import { contents } from "../../../_constants/constant";
import styles from "./styles.module.css"

const Quote =()=>{
    const isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;
    return (<div className={`d-flex align-items-center justify-content-center ${styles['quote']} font-family-poppins`}>
        <p className={`${isMobile ? `width-100 fs-20 fw-500`:`fs-35 fw-500`}`}>"{contents.about_us_quote}"<br /><span className="fw-700">{contents.about_us_quote_author}</span></p>
    </div>)
}
export default Quote