import {
  beauty,
  digitalMarketing,
  eCommerce,
  education,
  fashion,
  fintech,
  graphicDesigning,
  homeServiceIcon1,
  logistics,
  logoBlack,
  logoWhite,
  marketing,
  paidAds,
  publicRelations,
  retails,
  serviceIcon2,
  serviceIcon4,
  serviceIcon5,
  serviceIcon6,
  serviceIcon7,
  serviceIcon8,
  serviceIcon9,
  technology,
  tourism,
  videoEditing,
  websiteDevlopment,
} from "../_constants/images_assets";
const contents = {
  company_name: "FameX",
  mobile_number: 7828961990,
  email: "contact@famexmarketing.com",
  work_time: "10:00 AM To 06:30 PM",

  //home page
  home_page_title: "Amplify your business for unmatched proficiency through",
  home_page_typing: [
    "Public Relations",
    "Branding",
    "Marketing",
    "Social Media Marketing",
    "Digital Marketing",
  ],
  home_page_quote:
    "Connect your mind with ours and elevate your profile to new heights",
  home_page_about_us_heading1: "Why FameX ?",
  home_page_about_us_heading2: "",
  home_page_about_us_para:"At Fame X, we bring a touch of wit and spirit to every project, no matter how big or small. Our goal is to help you achieve marketing excellence that rivals the best in the industry. With our dedication and expertise, we go above and beyond to ensure your brand stands out from the crowd and achieves exceptional success.",
  home_page_industry_subtitle:
    "We Proudly Provide Services To Clients in the Following Industry",
  home_page_industries: [
    "Hospitality, Fashion & Lifestyle",
    "Beauty & Wellness",
    "E-Commerce",
    "Architecture & Interior",
    "Technology",
    "Fintech",
    "Logistics",
    "Retail",
    "Start-Ups",
    "Education",
  ],
  home_page_discussion_quote: "To work together let's have a cup of coffee !!",

  //about-us
  about_us_left_heading1: "one stop solution for all Your",
  about_us_left_heading2: "marketing needs",
  about_us_para1:
    "We are a team of innovative and creative brains, with customized innovative strategies to make your brand globally popular.",
  about_us_quote:
    "An idea can turn to magic or dust, depending on the talent that rubs against it.",
  about_us_quote_author: "Bill Bernbach",

  //services
  services: [
    {
      title: "Public Relations",
      icon: publicRelations,
      points:
        "One of the vital components for the functioning of a business, is a strategic communication discipline that involves managing relationships between an organization and the general public. It builds a positive image, handles crises, and promotes interests through media relations, editorials, branding, and more. Our tailored PR solutions drive success and reputation, helping clients achieve their goals with strategic communication. Our approach to communication is strategic and effective, ensuring that we help our clients achieve their objectives and build a positive reputation among the public.",
    },
    {
      title: "Marketing",
      icon: marketing,
      points:
        "Social Media Marketing (Facebook, Instagram, YouTube, Twitter, LinkedIn), E-Mail Marketing, Events & Exhibitions, Roadshows, Product Launch, Banners, Hoarding, Poster, Content Marketing",
    },
    {
      title: "Digital Marketing",
      icon: digitalMarketing,
      points:
        "Welcome to the world of digital marketing, where we harness the power of search engines, social media platforms, email communications, and websites to propel products and services forward. Through this dynamic medium, businesses gain access to invaluable customer data and insights, which in turn, supercharge their marketing strategies. The advantages of digital marketing are vast: encompasses cost-effectiveness, an expansive reach, precise targeting capabilities, heightened rates of engagement, and the ability to measure outcomes with precision.",
    },
    {
      title: "Graphic Designing",
      icon: graphicDesigning,
      points:
        "Logo Design, Packaging, Brochures, Pamphlets, Thumbnail, Catalogues, Showroom Design, Retail Outlet Design, Stall Design",
    },
    {
      title: "Video Editing",
      icon: videoEditing,
      points:
        "The manipulation and rearrangement of video footage to create a final product. It includes cutting and splicing scenes, adding special effects, sound design, and colour grading. Video editing can be used to enhance various content, including movies, TV shows, commercials, and social media videos.",
    },
    {
      title: "Paid Advertisements",
      icon: paidAds,
      points: "Paid ads, also known as pay-per-click (PPC) advertising, is a marketing strategy that calls for paying to display your ad to potential customers on various online platforms. These ads are often displayed on search engine result pages (SERPs), social media platforms, and other websites. The primary goal of paid advertising is to increase brand awareness, generate leads, and drive sales. Advertisers bid on specific keywords or target demographics to ensure their ads are shown to the right audience. Paid advertising possesses the potential to serve as a formidable asset for businesses, regardless of their scale or magnitude. It allows them to target their ideal customer, track their ad performance, and make data-driven decisions to improve their campaigns.",
    },
    {
      title: "Website Development",
      icon: websiteDevlopment,
      points:
        "At Fame X, web development is like building a sandcastle on the beach. You start with a vision in your mind and a pile of sand, and you use your creativity and expertise to mould it into something amazing. Our team of skilled developers will work with you to create a website that looks great and functions seamlessly. We use the latest web technologies and design trends to ensure your website stands out and provides an exceptional user experience.",
    },
  ],
  industries: [
    { title: "Hospitality and Tourism", icon: tourism, detail:"Fame X can turn your hospitality and tourism business into a destination hotspot! With creative website development, branding, and digital marketing solutions, we'll make sure your business stands out from the crowd and attracts visitors from far and wide. Let's give your customers an experience they'll never forget!" },
    { title: "Fashion & Lifestyle", icon: fashion, detail:"Fame X, the ultimate fashion and lifestyle wingman, ensures that your brand is the talk of the town! From being fashionable all year to controlling the fashion scene, we've got you covered. Trust us to raise the profile of your company and make you the talk of the town!" },
    { title: "Beauty & Wellness", icon: beauty, detail:"With Fame X, you have access to the power of your beauty business! Our talented artists work hard to make your brand shine and bring a new beginning to your beauty business by implementing exciting and revolutionary methods that will shine a light on your company like never before." },
    { title: "E-Commerce", icon: eCommerce, detail:"Step into our virtual store, where shopping becomes a pure pleasure. Immerse yourself in a world of limitless possibilities with a single click. Browse our enticing goods and embark on a fascinating journey of unparalleled treasures." },
    { title: "Technology", icon: technology, detail:"Elevate your tech brand with Fame X! Our expert strategies harness the power of marketing to boost technology products and services. From enhancing visibility to driving customer engagement, our innovative campaigns ensure your tech offerings stay ahead in the digital race. Partner with us for tech success!" },
    { title: "Fintech", icon: fintech, detail:"Marketing is essential for fintech companies to thrive in the digital age. It builds brand awareness, establishes credibility, and attracts investors and customers. Effective marketing strategies for fintech can showcase innovative solutions and make a company stand out in a tough industry, helping it grow and do well." },
    { title: "Logistics", icon: logistics, detail:"Fame X can aid your business in the logistics industry by providing insightful coverage of industry trends, advancements, and innovations. This can equip you with the necessary knowledge to make informed decisions, optimize operations, and stay ahead of the competition." },
    { title: "Retail", icon: retails, detail:"The retail industry is where you can find anything and everything, from funky socks to avocado toast. It is a shopping wonderland, where marketing takes centre stage, orchestrating the ballet of brand recognition, customer allure, and sales escalation. This marketing magic bridges the gap between retailers and their desired audience, making products shine and maintaining a competitive edge in a dynamic market. With effective strategies, retail enterprises flourish, expanding their clients and securing the path to prosperity and growth." },
    { title: "Education", icon: education, detail:`Marketing in educational sector is like a spotlight, helping schools and colleges to connect with more people and share what makes them special. It is like turning on a big sign that says, "Come learn with us!". In today's tough competition, smart marketing is a must for education industry to thrive.` },
  ],

  services_black_left_heading1: "Does Your Brand Need",
  services_black_left_heading2: "Supreme Branding? Or Marketing?",
  services_black_para:
    "FameX provides entire brand building strategies, including Public Relation, Social Media Management, Website Designing & Development, Crisis Management, Press Conference, Press Releases, SEO Tactics, Blogging etc. to accelerate your brand in a prime position.",
  links: {
    instagram: "https://instagram.com/famexmarketing",
    linkedin: "https://www.linkedin.com/company/media-pad/",
    facebook: "https://www.facebook.com/profile.php?id=61552222667723",
  },
};

export { contents };
