import React, { useEffect, useState } from "react";
import Footer from "../../_components/Footer/Footer";
import Header from "../../_components/Header/Header";
import AboutUs from "../../_components/HomePage/AboutUs/AboutUs";
import HomeHero from "../../_components/HomePage/HomeHero/HomeHero";
import Industries from "../../_components/HomePage/Industries/Industries";
import Services from "../../_components/HomePage/Services/Services";
import Steps from "../../_components/HomePage/Steps/Steps";
import styles from "./styles.module.css";
import { heroImg } from "../../_constants/images_assets";
import firebase from "firebase/compat/app";
import "firebase/database";
import {
  child,
  get,
  getDatabase,
  onValue,
  ref,
} from "firebase/compat/database";
import CssLoader from "../../_common/cssloader";
import Loader from "../../_components/Loader/Loader";
import Quote from "../../_components/AboutPage/Quote/Quote";
import { Helmet } from "react-helmet";
import OurVision from "../../_components/HomePage/OurVision/OurVision";

let data = "";

const Home = (props) => {
  // const [data, setData] = useState([]);
  const currentUrl = window.location.href;
  const isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;
  useEffect(() => {}, []);
  return (
    <div
      className={`${styles["main-home"]} width-100 height-100 font-family-inter`}
    >
      <Helmet>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <div className={`${styles["hero-bg"]}`}>
        <div>
          <Header colorWhite={true} borderBottom={true} whiteLogo={true} />
          <HomeHero />
        </div>
      </div>
      <AboutUs buttonTxt={"About Us"} />
      <Quote />
      <OurVision/>
      <div className={`d-flex flex-column align-items-center justify-content-center ${styles['quote']} font-family-poppins`}>
        <p className={`${isMobile ? `width-100 fs-20 fw-500`:`fs-20 fw-500`}`}> <span className="fw-700 fs-35">Our Mission</span><br /> Our established procedures and approaches are designed to help our clients improve their quality of business.  We're on a mission to shape a leading vision that thrives even when success is achieved. With marketing prowess, we conquer realms of power and dignity.</p>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
