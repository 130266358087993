import React, { createElement, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styles from "./styles.module.css";
import {
  linkedinIcon,
  instaIcon,
  twitterIcon,
  rightArrowWhite,
  MLogo,
  emailIcon,
  callIcon,
  locationIcon,
  facebookIcon,
  instagramIcon,
  logoWhite,
} from "../../_constants/images_assets";
import { contents } from "../../_constants/constant";
import firebase from "firebase/compat/app";
import "firebase/database";
import "firebase/compat/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const [instaData, setInstaData] = useState([]);
  const history = props.history;
  useEffect(() => {
    getInstaData();
  }, []);

  const onChange = (e) => {
    console.log("email", e.target.value);
    setEmail(e.target.value);
  };
  const onClick = async () => {
    const response = await fetch(
      "https://gp-todo.onrender.com/users/subscriber/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      }
    );
    const data = await response.json();
    data.status_code === 200 &&
      toast.success("Now you are subscribed with us!!!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        // onClose:window.location.reload()
      });
    data.status_code === 200 && setSubscribed(true);
  };

  const getInstaData = async () => {
    const response = await fetch(
      "https://graph.instagram.com/me/media?limit=9&fields=id,media_type,media_url,thumbnail_url&access_token=IGQVJXdnFGVTRXYlp6YUpSaXZAYMjVFLXVSMkhDVDc2b0JHcGZAKbzFmd1VzMmp2NE1GVk8yaEVqYXlBaGtDcWwxb1NrNGI3UWJmNWIwemVkNzZAfaWJ2VUJfTzY3TEs3elhrWG1SZAGFvY0lqdXVsSFZAFNgZDZD",
      {
        method: "GET",
      }
    );
    const data = await response.json();
    console.log("data", data);
    setInstaData(data.data);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div>
      <ToastContainer />
      <div
        className={`d-flex ${
          isMobile && `flex-column`
        } justify-content-center align-items-center ${
          styles["footer"]
        } margin-top40`}
      >
        <div
          className={`${styles["left-footer"]} ${
            !isMobile && `padding-left10p`
          } ${isMobile && `width-100`}`}
        >
          <div className={`${styles["logo-section"]} d-flex`}>
            <img src={logoWhite} alt="" width={"160px"} height={"120px"} />
            <div>
              {/* <p className={`${styles["company_name"]}`}>Mediapad</p>
              <p>Marketing | Communication | Consultancy</p> */}
            </div>
          </div>
          <p className={`margin-top40 ${isMobile && `text-align-center`}`}>
          Fame X is a distinguished marketing and communication firm specializing in the development of innovative strategies tailored to address unique challenges and drive impactful solutions.
          </p>
          <div
            className={`${isMobile && `d-flex flex-column align-items-center`}`}
          >
            <p className="margin-top40 fs-20 fw-700">Contact</p>
            <div className="d-flex align-items-center">
              <img
                src={emailIcon}
                alt=""
                width={"30px"}
                height={"30px"}
                className={`margin-right10`}
              />
              {contents.email}
            </div>
            <div className="d-flex align-items-center margin-top10">
              <img
                src={callIcon}
                alt=""
                width={"30px"}
                height={"25px"}
                className={`margin-right10`}
              />
              +91 78289 61990
            </div>
            <div className="d-flex align-items-center margin-top10">
              <img
                src={locationIcon}
                alt=""
                width={"25px"}
                height={"30px"}
                className={`margin-right10`}
              />
              New Delhi
            </div>
          </div>
        </div>
        <div
          className={`${styles["right-footer"]} ${
            !isMobile && `padding-left10p`
          } ${
            isMobile &&
            `width-100 d-flex flex-column align-items-center margin-top40`
          }`}
        >
          <p className="fs-20 fw-700">Follow</p>
          <p className="margin-top20">
            Sign up to get the latest news on our product.
          </p>
          <p className="margin-top40">E-mail *</p>
          <div className="d-flex margin-top10">
            <input
              type="text"
              placeholder=""
              name="email"
              id="email"
              onChange={onChange}
            />
            <button
              className="backColorBlack colorWhite"
              onClick={() => {
                onClick();
              }}
            >
              {subscribed ? `Subscribed` : `Subscribe`}{" "}
            </button>
          </div>
          <div className={`d-flex ${styles["social-media"]}`}>
            <a href="">
              <img src="" alt="" />
            </a>
          </div>
          <div className="margin-top40 d-flex">
            <a href={contents.links.facebook} target="_blank">
              <img
                src={facebookIcon}
                width="30px"
                height={"25px"}
                className={`margin-right10`}
                alt=""
              />
            </a>
            <a href={contents.links.linkedin} target="_blank">
              <img
                src={linkedinIcon}
                width="30px"
                height={"30px"}
                className={`margin-right10`}
                alt=""
              />
            </a>
            <a href={contents.links.instagram} target="_blank">
              <img src={instagramIcon} width="28px" height={"28px"} alt="" />
            </a>
          </div>
        </div>
        {/* <div
          className={`${styles["instagram"]}  ${
            isMobile && `width-90 margin-left5p margin-top20`
          }`}
        >
          <div
            className={`${styles["instagram-title"]} d-flex justify-content-between align-items-center`}
            onClick={() => {
              goToInsta();
            }}
          >
            <div className="d-flex align-items-center">
              <img src={MLogo} alt="" width={"30px"} />
              <p className="">Mediapad</p>
            </div>
            <button>Follow</button>
          </div>
          <div
            className={`d-flex flex-wrap justify-content-between ${styles["instagram-posts"]}`}
          >
            {instaData?.map((item) => {
              return item?.media_type === "VIDEO" ? (
                <img
                  src={item?.thumbnail_url}
                  alt=""
                  className={`${styles["video-img-posts"]}`}
                />
              ) : (
                <img src={item?.media_url} alt="" width={"150px"} />
              );
            })}
          </div>
        </div> */}
      </div>
      <div className={`backColorBlack colorWhite ${styles["copyright"]}`}>
        Copyright 2023 FameX
      </div>
    </div>
  );
};
export default Footer;
