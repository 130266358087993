import React from "react";
import Footer from "../../_components/Footer/Footer";
import Header from "../../_components/Header/Header";
import { contents } from "../../_constants/constant";
import styles from "./styles.module.css";
import { Helmet } from "react-helmet";

const IndustriesWeServe = () => {
  const currentUrl = window.location.href;
  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div
      className={`${styles["industries"]} width-100 height-100 font-family-inter d-flex flex-column align-items-center`}
    >
      <Helmet>
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Header />
      <div
        className={`${styles["industries-head"]} ${
          isMobile && `height-200p`
        } d-flex justify-content-center align-items-center`}
      >
        <p className={`${isMobile ? "fs-28" : "fs-60"}`}>Industries we serve</p>
      </div>
      <div
        className={`${styles["industries-body"]} d-flex justify-content-between margin-top20`}
      >
        {contents.industries.map((industry) => {
          return (
            <div className="margin-top40 padding-16">
              <p className={`margin-top10 fs-22 fw-700 ${styles['head']}`}>{industry.title}</p>
              <img src={industry.icon} width="70px" height={`70px`} alt="" />
              <p className={`margin-top10 ${styles['detail']}`}>{industry.detail}</p>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};
export default IndustriesWeServe;
