import './App.css';
import React,{useEffect,useState} from "react"
import {BrowserRouter,Route,Routes} from "react-router-dom"
import Home from "./_webapp/Home/home";
import About from "./_webapp/About/About";
import Cssloader from './_common/cssloader';
import Services from './_webapp/Services/Services';
import Contact from './_webapp/Contact/Contact';
import firebase from 'firebase/compat/app';
import 'firebase/database';
import IndustriesWeServe from './_webapp/IndustriesWeServe/IndustriesWeServe';
const firebaseConfig = {
  apiKey: "AIzaSyC6Egz69QNBB-4Bpv0LwkrOLiF1LP-ldOE",
  authDomain: "mediapad-2ed34.firebaseapp.com",
  projectId: "mediapad-2ed34",
  storageBucket: "mediapad-2ed34.appspot.com",
  messagingSenderId: "807319104427",
  appId: "1:807319104427:web:e7e023817471538579c0ee",
  measurementId: "G-FCZT9MZVS6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
function App() {
  return (
    <div className="App">
      <Cssloader/>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home/>} exact/>
          <Route path={"/about"} element={<About/>} exact/>
          <Route path={"/services"} element={<Services/>} exact/>
          <Route path={"/industries"} element={<IndustriesWeServe/>} exact/>
          <Route path={"/contact"} element={<Contact/>} exact/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
