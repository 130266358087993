import React from "react";
import styles from "./styles.module.css"
import { heroImg,rightArrowWhite,homeServiceIcon1 } from "../../../_constants/images_assets";

const Card =({service})=>{
    const isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;
    return (<div className={`${!isMobile && `d-flex`} align-items-center ${styles['card']} ${isMobile && `width-100`}`}>
        <img src={service.icon} alt="" />
        {!isMobile?<div>
        <h5 className={`fs-20 fw-700 margin-top10`}>{service.title}</h5>
        <p className="margin-top10">
            {service.points}
            {/* {service.points.map((point)=>{
                return <li>{point}</li>
            //    return ( index!==0?" , "+  point:""+  point )    
            })} */}
        </p>
        </div>:<><h5 className={`fs-20 fw-700 margin-top10`}>{service.title}</h5>
        <p className="margin-top10">
            {service.points}
            {/* {service.points.map((point)=>{
                return <li>{point}</li>
            //    return ( index!==0?" , "+  point:""+  point )    
            })} */}
        </p></>
        }
    </div>)
}
export default Card