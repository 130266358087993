
import React from "react";
import styles from "./styles.module.css"
import { rightArrowWhite,aboutUsHomeImg,aboutUsImageIcon } from "../../../_constants/images_assets";
import ImageWithIcon from "../../ImageWithIcon/ImageWithIcon";
import { contents } from "../../../_constants/constant";

const AboutUs=({head1,head2,para,buttonTxt,darkBack,para2,link="/about"})=>{
    const isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;
    return (
        <div className={`d-flex ${isMobile && `flex-column`} justify-content-center align-items-center ${!isMobile && `padding-left90`} ${styles['about-us']} ${darkBack?styles['darkBack']:""}`}>
            {!isMobile?<div><ImageWithIcon image={aboutUsHomeImg} icon={aboutUsImageIcon}/></div>
            :
            <div className="width-100">
                <img src={aboutUsHomeImg} alt="" width={"100%"}/>    
            </div>}
            <div className={`${isMobile && `width-100 d-flex flex-column align-items-center margin-bottom40`}`}>
                <h2 className="fs-40 font-family-poppins">{contents.home_page_about_us_heading1}<br/>{contents.home_page_about_us_heading2}</h2>
                <p className="fs-18 font-family-inter">{contents.home_page_about_us_para}</p>
                <ul>
                    <li>Transparency</li>
                    <li>Dedication</li>
                    <li>Creativity</li>
                    <li>Ability to execute</li>
                    <li>Flexibility</li>
                </ul>
                {/* <p className="fs-18 font-family-inter">{contents.about_us_services_para2}</p> */}
                <button className="backColorPrimary colorWhite fs-18"  onClick={()=>{window.location.pathname=link}}>{buttonTxt}<img src={rightArrowWhite} alt="" /></button>
            </div>
        </div>
    )
}
export default AboutUs