import React, { useState } from "react";
import styles from "./styles.module.css";
import { logoWhite, logoBlack } from "../../_constants/images_assets";

const Header = ({ colorWhite, borderBottom, whiteLogo }) => {
  const [show, setShow] = useState(false);
  const onClick = () => {
    // const element = document.getElementById('hamburger-details');
    setShow(!show);
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 990px)"
  ).matches;
  return (
    <div
      className={`col-12 d-flex align-items-center ${styles["header"]} font-family-inter fs-20 fw-600`}
    >
      <header
        className="d-flex align-items-center justify-content-between"
        style={{ borderBottom: borderBottom ? "1px solid #fff" : "" }}
      >
        <img
          src={whiteLogo ? logoWhite : logoBlack}
          alt=""
          width={isMobile ? "100px" : "150px"}
          height={isMobile ? "50px" : "100px"}
          onClick={() => {
            window.location.pathname = "/";
          }}
          style={{ cursor: "pointer" }}
        />
        {!isMobile ? (
          <div className="d-flex">
            <p
              onClick={() => {
                window.location.pathname = "/";
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/about";
              }}
            >
              About
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/services";
              }}
            >
              Services
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/industries";
              }}
            >
              Industries We Serve
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/contact";
              }}
            >
              Contact
            </p>
          </div>
        ) : (
          <div
            className={`${styles["hamburger"]}`}
            onClick={() => {
              onClick();
            }}
          >
            <div className={`${styles["hamburger-line"]}`}></div>
            <div className={`${styles["hamburger-line"]}`}></div>
            <div className={`${styles["hamburger-line"]}`}></div>
          </div>
        )}
        {show && (
          <div className={`${styles["hamburger-details"]} `}>
            <p
              onClick={() => {
                window.location.pathname = "/";
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/about";
              }}
            >
              About
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/services";
              }}
            >
              Services
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/industries";
              }}
            >
              Industries We Serve
            </p>
            <p
              onClick={() => {
                window.location.pathname = "/contact";
              }}
            >
              Contact
            </p>
          </div>
        )}
      </header>
    </div>
  );
};
export default Header;
